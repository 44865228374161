import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

function Gtag() {
  const gtagId = process.env.REACT_APP_GTAG_ID
  const location = useLocation()

  // Track page views on route change
  React.useEffect(() => {
    if (window.gtag && gtagId) {
      window.gtag('config', gtagId, {
        page_path: location.pathname + location.search,
      })
    }
  }, [location.pathname, location.search, gtagId])

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`}
      />
      <script id="gtag-init">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtagId}', {
            page_path: '${location.pathname + location.search}'
          });
        `}
      </script>
    </Helmet>
  )
}

export default Gtag
