import { Outlet } from 'react-router-dom'
import Gtag from '../utils/google/gtag'
import MetaPixel from '../utils/meta/metaPixel'

export const Root = () => {
  return (
    <>
      <MetaPixel />
      <Gtag></Gtag>
      <Outlet />
    </>
  )
}
