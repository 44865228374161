import BlogImg1 from '../../style/assets/blog_img1.jpeg'
import BlogImg1Social from '../../style/assets/blog_img1_960w.jpeg'
import BlogImg2 from '../../style/assets/blog_img2.jpeg'
import BlogImg2Social from '../../style/assets/blog_img2_960w.jpeg'
import BlogImg3 from '../../style/assets/blog_img3.jpeg'
import BlogImg3Social from '../../style/assets/blog_img3_960w.jpeg'
import BlogImg4 from '../../style/assets/blog_img4.jpeg'
import BlogImg4Social from '../../style/assets/blog_img4_960w.jpeg'
import BlogImg5 from '../../style/assets/blog_img5.jpeg'
import BlogImg5Social from '../../style/assets/blog_img5_960w.jpeg'
import BlogImg6 from '../../style/assets/blog_img6.webp'
import BlogImg6Social from '../../style/assets/blog_img6_w1200.jpg'
import BlogImg7 from '../../style/assets/blog_img7.webp'
import BlogImg7Social from '../../style/assets/blog_img7_w1200.jpg'
import BlogImg8 from '../../style/assets/blog_img8.webp'
import BlogImg8Social from '../../style/assets/blog_img8_w1200.jpg'
import { BlogMetadata } from '../type/blog-metadata'

export const blogPostMetadata: BlogMetadata[] = [
  {
    postDate: new Date('2/20/2023'),
    title: 'Ползите от редовното пране на матраци',
    readInMin: 1,
    image: {
      src: BlogImg2,
      srcSocial: BlogImg2Social,
      alt: 'спалня с светло кафяви стени и под. Стаята съдържа легло с бяло покривало, възглавници и пейка',
      author: {
        name: 'Kenny Eliason',
        url: 'https://unsplash.com/@neonbrand',
      },
    },
    to: '/blog/polzite-ot-redovnoto-prane-na-matraci',
    description:
      'Редовното пране на матраци премахва натрупаните акари, бактерии и прах, осигурявайки по-здравословна и комфортна среда за сън.',
  },
  {
    postDate: new Date('3/1/2023'),
    title: 'Ползите от основното почистване на дома',
    readInMin: 1,
    image: {
      src: BlogImg1,
      srcSocial: BlogImg1Social,
      alt: 'ръка в ръкавица, държаща спрей бутилка',
      author: {
        name: 'Towfiqu barbhuiya',
        url: 'https://unsplash.com/@towfiqu999999',
      },
    },
    to: '/blog/polzite-ot-osnovnoto-pochistvane-na-doma',
    description:
      'Научете как основното почистване на дома премахва натрупаните замърсявания, подобрява качеството на въздуха и създава по-здравословна и комфортна среда за вашето семейство.',
  },
  {
    postDate: new Date('3/30/2023'),
    title: 'Ползите от професионалното почистване на дома',
    readInMin: 2,
    image: {
      src: BlogImg4,
      srcSocial: BlogImg4Social,
      alt: 'ръка в ръкавица, държаща спрей бутилка',
      author: {
        name: 'JESHOOTS.COM',
        url: 'https://unsplash.com/@jeshoots',
      },
    },
    to: '/blog/polzite-ot-profesionalnoto-pochistvane-na-doma',
    description:
      'Открийте как професионалното почистване на дома може да подобри хигиената, да спести време и да осигури комфортна и здравословна среда за вашето семейство.',
  },
  {
    postDate: new Date('4/7/2023'),
    title: 'Какво ни отличава от конкуренцията',
    readInMin: 1,
    image: {
      src: BlogImg5,
      srcSocial: BlogImg5Social,
      alt: 'ръка на жена, която пише в тетратка',
      author: {
        name: 'Glenn Carstens-Peters',
        url: 'https://unsplash.com/@glenncarstenspeters',
      },
    },
    to: '/blog/kakvo-ni-otlichava-ot-konkurenciqta',
    description:
      "Открийте как Marty's Cleaning се отличава от конкуренцията с висококачествени почистващи услуги, персонализиран подход и иновативни технологии, гарантиращи безупречна чистота и удовлетвореност на клиентите.",
  },
  {
    postDate: new Date('5/17/2023'),
    title: '10 причини, поради които да перете редовно дивана си',
    readInMin: 2,
    image: {
      src: BlogImg3,
      srcSocial: BlogImg3Social,
      alt: 'уютна всекидневна със сив диван, възглавници и дървена маса',
      author: {
        name: 'Nathan Fertig',
        url: 'https://unsplash.com/@nathanfertig',
      },
    },
    to: '/blog/10-prichini-poradi-koito-da-perete-redovno-divana-si',
    description:
      'Защо редовното пране на дивана е важно за здравето и уюта у дома.',
  },
  {
    postDate: new Date('12/15/2024'),
    title: 'Моето Cleaning Expo приключение',
    readInMin: 3,
    image: {
      src: BlogImg6,
      srcSocial: BlogImg6Social,
      alt: 'Знак рекламиращ предстоящо събитие, „CLEANING EXPO“, което ще се проведе на 22 и 23 октомври 2024 г.',
    },
    to: '/blog/moeto-cleaning-expo-prikluchenie',
    description:
      'Моето вълнуващо приключение на Cleaning Expo, където открих иновативни технологии и продукти за почистване и почерпих ценни идеи за подобряване на услугите в почистващата индустрия.',
  },
  {
    postDate: new Date('12/22/2024'),
    title:
      "Cleaning Expo 2024: Новите тенденции в почистването от Marty's Cleaning",
    readInMin: 2,
    image: {
      src: BlogImg7,
      srcSocial: BlogImg7Social,
      alt: 'Знак рекламиращ предстоящо събитие, „CLEANING EXPO“, което ще се проведе на 22 и 23 октомври 2024 г.',
    },
    to: '/blog/cleaning-expo-2024-novite-tendencii',
    description:
      'Присъединете се към мен в моето приключение на Cleaning Expo, където открих иновативни почистващи продукти и експертни съвети за по-добра хигиена.',
  },
  {
    postDate: new Date('1/30/2025'),
    title:
      'Как чисти всяка зодия – истини и забавни факти за домакинската работа',
    readInMin: 4,
    image: {
      src: BlogImg8,
      srcSocial: BlogImg8Social,
      alt: 'Забавна илюстрация на зодиакалните знаци, изобразени като хора, ангажирани с почистване. Динамична и цветна сцена с комичен стил.',
    },
    to: '/blog/kak-chisti-vsqka-zodiq-istini-i-zabavni-fakti-za-domakinskata-rabota',
    description:
      'Открий как всяка зодия подхожда към чистенето! Кои са най-големите ентусиасти, кои го отлагат до последно и какво казват звездите за домакинските навици на различните знаци?',
  },
]
